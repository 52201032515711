@import "src/variables.scss";

.btn {
  background: transparent;
  padding: $base-offset / 2 $base-offset;
  font-weight: bold;
  cursor: pointer;
  border: 0;
  height: auto;
  border-radius: 0;

  &:disabled {
    opacity: 0.7;
  }

  &-outlined-black {
    border: 1px solid $porsche-white;
    color: $porsche-white !important;
    transition: all 0.3s ease;

    &:hover {
      color: $porsche-white !important;
      background-color: $porsche-white !important;
      transition: all 0.3s ease;
      border: 1px solid $porsche-white;
    }

    ::ng-deep {
      span {
        color: $porsche-black !important;
      }
    }
  }

  &-outlined-yellow {
    border: 1px solid $porsche-black;
    color: $porsche-black !important;

    ::ng-deep {
      span {
        color: $porsche-white !important;
      }

      &:hover {
        color: $porsche-white !important;
        background-color: $porsche-black;
      }
    }
  }

  &-outlined-white {
    border: 1px solid $porsche-white;
    color: $porsche-white !important;
    transition: all 0.3s ease;

    &:hover {
      color: $porsche-black !important;
      background-color: $porsche-white !important;
      transition: all 0.3s ease;
      border: 1px solid $porsche-white;
    }

    &:focus,
    &:active {
      color: $porsche-black !important;
      background-color: darken($porsche-white, 10%) !important;
      transition: all 0.3s ease;
      border-color: darken($porsche-white, 10%) !important;
    }

    ::ng-deep {
      span {
        color: $porsche-white !important;
      }
    }
  }

  &-outlined-blue {
    border: 1px solid $porsche-blue;
    color: $porsche-white !important;
    transition: all 0.3s ease;

    &:hover {
      color: $porsche-white !important;
      background-color: $porsche-blue !important;
      transition: all 0.3s ease;
      border: 1px solid $porsche-blue;
    }

    &:focus,
    &:active {
      color: $porsche-white !important;
      background-color: darken($porsche-blue, 10%) !important;
      transition: all 0.3s ease;
      border-color: darken($porsche-blue, 10%) !important;
    }

    ::ng-deep {
      span {
        color: $porsche-white !important;
      }
    }
  }

  &-outlined-green {
    border: 1px solid $success-color;
    color: $porsche-white !important;
    transition: all 0.3s ease;

    &:hover {
      color: $porsche-black !important;
      background-color: $success-color !important;
      transition: all 0.3s ease;
      border: 1px solid $success-color;
    }

    &:focus,
    &:active {
      color: $porsche-white !important;
      background-color: darken($success-color, 10%) !important;
      transition: all 0.3s ease;
      border-color: darken($success-color, 10%) !important;
    }

    ::ng-deep {
      span {
        color: $porsche-white !important;
      }
    }
  }

  &-outlined-red {
    border: 1px solid $error-color;
    color: $porsche-black !important;
    transition: all 0.3s ease;

    &:hover {
      color: $porsche-white !important;
      background-color: $error-color !important;
      transition: all 0.3s ease;
      border: 1px solid $error-color;
    }

    ::ng-deep {
      span {
        color: $porsche-white !important;
      }
    }
  }

  &-red {
    background-color: $error-color;
    color: $porsche-white !important;
    border: 1px solid $error-color;
    transition: all 0.3s ease;

    &:hover {
      background-color: darken($error-color, 10%);
      border: 1px solid darken($error-color, 10%);
      color: $porsche-white !important;
      transition: all 0.3s ease;
    }
  }

  &-blue {
    background-color: $porsche-blue;
    color: $porsche-white !important;
    border: 1px solid $porsche-blue;
    transition: all 0.3s ease;

    &:hover,
    &:disabled {
      background-color: darken($porsche-blue, 10%) !important;
      border: 1px solid darken($porsche-blue, 10%);
      color: $porsche-white !important;
      transition: all 0.3s ease;
    }

    &:focus {
      background-color: darken($porsche-blue, 10%);
    }
  }

  &-yellow {
    background-color: $porsche-black;
    color: $porsche-white !important;
    border: 1px solid $porsche-white;
    transition: all 0.3s ease;

    &:hover {
      background-color: $porsche-white;
      border: 1px solid $porsche-black;
      color: $porsche-white !important;
      transition: all 0.3s ease;
    }
  }

  &-black {
    background-color: $porsche-black;
    color: $porsche-white !important;
    transition: all 0.3s ease;

    &:focus {
      background-color: lighten($porsche-black, 10%);
    }

    &:hover {
      background-color: lighten($porsche-black, 10%);
      color: $porsche-white !important;
      transition: all 0.3s ease;
    }
  }

  &-disabled {
    cursor: default;
    opacity: 0.15;
  }

  @media screen and (max-width: 960px) {
    //width: 100%;
  }
}
