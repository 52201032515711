$base-offset: 16px;

$porsche-black: #151515;
$porsche-white: #ffffff;
$porsche-blue: #112eab;

$primary-color: #eeeeee;
$primary-color-text: #181818;
$primary-color-light: #fff;
$primary-color-light-text: $primary-color-text;
$primary-color-dark: #bcbcbc;
$primary-color-light-text: $primary-color-text;

$secondary-color: #c62828;
$secondary-color-text: #f9f9f9;
$secondary-color-light: #ff5f52;
$secondary-color-light-text: $primary-color-text;
$secondary-color-dark: #8e0000;
$secondary-color-dark-text: $secondary-color-text;

$ragnar-color: #ae7e43;

$error-color: $secondary-color;
$warning-color: #faad14;
$success-color: #52c41a;
$info-color: #1890ff;

$desktop-view-max-width: 960px;

$championship-color: $porsche-blue;
$community-race-color: #e32300;
$playoff-color: #99e300;
