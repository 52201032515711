/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap");

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@import "src/variables.scss";

@import "src/styles/modal.scss";
@import "src/styles/typography.scss";
@import "src/styles/button.scss";
@import "src/styles/select.scss";

@import "quill/dist/quill.snow.css";

@font-face {
  font-family: "Porsche";
  src: url("assets/font/PorscheNext-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Porsche";
  font-weight: bold;
  src: url("assets/font/PorscheNext-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Porsche-Bold";
  font-weight: bold;
  src: url("assets/font/PorscheNext-Bold.ttf") format("truetype");
}

body {
  font-family: "Porsche", "Roboto", sans-serif;
  font-size: 16px;
  // background-color: transparent;
  // font-family: Arial;// 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}

.inverted {
  color: $porsche-black !important;
  background-color: $porsche-white;

  * {
    color: $porsche-black !important;
  }

  .clear-inversion * {
    color: inherit !important;
  }
}

.button {
  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

.app-layout {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    padding: 0 $base-offset;
  }
}

.carousel-item {
  width: 100%;
  // font-size: 2em;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}

.section-heading {
  font-size: 3em;
  line-height: 1em;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Porsche-Bold", "Roboto", sans-serif;

  &.red {
    color: $porsche-white;
  }

  &.white {
    color: $porsche-white;
  }

  &.black {
    color: $porsche-white;
  }

  @media screen and (max-width: $desktop-view-max-width) {
    font-size: 2em;
  }
}

.subsection-heading {
  font-size: 2em;
  line-height: 1em;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Porsche-Bold", "Roboto", sans-serif;

  &.red {
    color: $porsche-white;
  }

  &.white {
    color: $porsche-white;
  }

  @media screen and (max-width: $desktop-view-max-width) {
    font-size: 1.5em;
  }
}

.centered {
  text-align: center;
}

.ant-table-placeholder {
  background-color: transparent;
}

.ragnar-hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $porsche-blue;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $porsche-white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($porsche-white, 10%);
}

.w-100 {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

/*
.ragnar-heading {
    font-family: 'Oswald', 'Roboto', sans-serif;
}

nz-content {
    // margin: 0 24px;
}

.inner-content {
    padding: $base-offset;
    background: $primary-color-light;
    height: 100%;

    &.inner-content-no-padding {
        padding: 0;
    }
}

.content-row {
    margin-bottom: $base-offset;
}

.float-right {
    float: right;
}

.ragnar-box {
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    overflow: auto;

    &:not(:first-child) {
        margin: 16px auto;
    }
}

.ragnar-margin {
    margin: $base-offset;
}

.ragnar-margin-top {
    margin: $base-offset 0;
}

.ragnar-error {
    color: $error-color;
}

.ragnar-warning {
    color: $warning-color;
}

.gutter-col {
    margin-bottom: $base-offset;
}

.app-loader-outer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .app-loader-middle {
        display: table-cell;
        vertical-align: middle;

        .app-loader-inner {
            margin-left: auto;
            margin-right: auto;
            width: 50%;
        }
    }
}

.ant-spin-dot-item:nth-child(3), .ant-spin-dot-item:nth-child(1) {
    background-color: #ae7e43;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.text-centered {
    text-align: center;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;

    &.flex-col {
        flex-direction: column;
    }

    &.flex-row {
        flex-direction: row;
    }
}

.clearpadding {
    padding: 0;
}


.clickable {
    cursor: pointer;
}

*/
