@import "src/variables.scss";

.ant-modal-content {
  background-color: $porsche-white;
}

.ant-modal-header {
  background-color: $porsche-white;

  .ant-modal-title {
    color: $porsche-black !important;
    font-weight: bold;
  }
}

.ant-modal-close {
  color: $porsche-black !important;
  font-weight: bold;
}

.pescp-2023-modal {
  .ant-modal-header {
    background-color: $porsche-blue;

    .ant-modal-title {
      color: $porsche-white !important;
    }
  }

  .ant-modal-content {
    background-color: $porsche-black;
    color: $porsche-white;
  }

  .ant-modal-close {
    color: $porsche-white !important;
  }

  .ant-modal-confirm-title,
  .ant-modal-confirm-content {
    color: $porsche-white;
  }
}
