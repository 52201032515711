@import "src/variables.scss";

h1,
h2,
h3,
h4,
h5,
h6,
section {
  color: $porsche-white;
}

.header-decorator {
  position: relative;

  &::before {
    content: url(/assets/images/pescp-2022/x-icon.webp);
    position: absolute;
    left: -48px;

    @media screen and (max-width: 680px) {
      content: inherit;
    }
  }
}
