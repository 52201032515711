@import "src/variables.scss";

.pescp-2023-select {
  .ant-select-dropdown {
    background-color: $porsche-black;
  }

  .ant-select-item {
    color: $porsche-white;
    padding: 8px 16px;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: darken($porsche-blue, 10%);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $porsche-blue;
  }
}
